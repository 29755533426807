import instance from "../../config/axios";
import {
  ParseError,
  getAuthHeader,
  getWorkspaceId,
  gettingEmail,
} from "../../config/utils";
import { getLocalStorageItem } from "../../config/AuthSetting";

const updateProductSettings = async (formData) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .put(`/settings/update-product-settings`, formData, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const updateUserNoficationSettings = async (formData) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .put(`/notificationSettings`, formData, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const updateSocialMediaSettings = async (formData) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .put(`/socialMediaSettings`, formData, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const updateOrderSettings = async (formData) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .put(`/settings/update-orders-settings`, formData, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const GetWorkspaceAllUsers = async (id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();

  return instance
    .get(`/workspaces/members/workspaceId=${workspaceId}` + id, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const GetWorkspaceSettings = async (id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .get(`/settings?workspaceId=${id}`, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const GetWorkspaceAllUsersByModule = async (id, module) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .get(`/members/by-module?workspaceId=${id}&module=${module}`, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const GetUserWorkSpaces = async () => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .get("/workspaces/user", token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const GetWorkspaceUsers = async (id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .get("/workspaces/users/" + id, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const getWorkSpaceAgents = async (id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .get("/workspaces/" + id, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          const agents = response.data.members.filter(
            (member) => member.role === "agent"
          );

          return {
            ...responseData,
            status: 200,
            data: { agents: agents },
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const getWorkSpaceMembers = async (id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .get("/workspaces/" + id, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const GetWorkSpace = async (id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .get("/workspaces?workspaceId=" + id, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const GetWebsite = async () => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const token = await getAuthHeader();
  const workspaceId = getLocalStorageItem("workspaceId");

  return instance
    .get(`products/websites/getWebsite?workspaceId=${workspaceId}`, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const GetNotification = async (id, limit) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .get(`/notification/${id}?limit=${limit}`, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const AddWorkSpace = async (data) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("file", data.file);
  formData.append("email", data.email);
  formData.append("contact", data.contact);
  formData.append("address", data.address);
  formData.append("businessCategory", data.businessCategory);
  formData.append("numberOfProducts", data.numberOfProducts);
  formData.append("monthlyOrders", data.monthlyOrders);
  const token = await getAuthHeader("single");
  return instance
    .post(`/workspaces`, formData, token)
    .then((response) => {
      if (response.status === 200) {
        response = response.data;
        if (response.code === 200) {
          const data = response.data;
          return {
            ...responseData,
            status: 200,
            message: response.message,
            data,
          };
        }
        return {
          ...responseData,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const AddWorkSpaceMember = async (id, data) => {
  try {
    const token = await getAuthHeader();
    const response = await instance.post(
      `/workspaces/members/${id}`,
      data,
      token
    );
    return response.data;
  } catch (err) {
    throw err.response.data || err;
  }
};

const removeWorkSpaceMember = async (workspaceId, id) => {
  try {
    const token = await getAuthHeader();
    const response = await instance.delete(
      `/workspaces/members/${workspaceId}?memberId=${id}`,
      token
    );
    return response.data;
  } catch (err) {
    throw err.response.data || err;
  }
};

const applyRoleToWorkSpaceMember = async (workspaceId, data) => {
  try {
    const token = await getAuthHeader();
    const response = await instance.put(
      `/workspaces/members/${workspaceId}`,
      data,
      token
    );
    return response.data;
  } catch (err) {
    return err.response.data || err;
  }
};

const UpdateWorkSpace = async (data) => {
  try {
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    const token = await getAuthHeader("single");
    const response = await instance.put(`/workspaces`, formData, token);
    return response.data;
  } catch (err) {
    return err.response.data || err;
  }
};

const UpdateChatGptApiKey = async (data) => {
  try {
    const token = await getAuthHeader();
    const response = await instance.put(
      `/workspaces/update-chatGptApiKey`,
      data,
      token
    );
    return response.data;
  } catch (err) {
    return err?.response?.data || err;
  }
};

const DeleteWorkspace = async (id) => {
  try {
    const token = await getAuthHeader();
    const response = await instance.delete(
      `/workspaces?workspaceId=${id}`,
      token
    );
    return response.data;
  } catch (err) {
    return err.response.data || err;
  }
};

const ResetWorkspace = async (body) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .post(`/workspaces/resetDummyWorkspaces`, body, token)
    .then((response) => {
      if (response.status === 200) {
        response = response.data;
        if (response.code === 200) {
          const data = response.data;
          return {
            ...responseData,
            status: 200,
            message: response.message,
            data,
          };
        }
        return {
          ...responseData,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const UpdateNotification = async (formData, id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const workspaceId = getLocalStorageItem("workspaceId");
  const token = await getAuthHeader();
  return instance
    .put(
      `/notification/updateNotification/${id}`,
      { ...formData, workspaceId: workspaceId },
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const GetAllNotification = async (userId, page = 1, limit = 100000) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .get(`/notification/${userId}?page=${page}&limit=${limit}`, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            status: 200,
            data: response?.data,
            count: response?.count,
            totalCount: response?.totalCount,
            message: response?.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const UpdateNotificationAlert = async (formData, id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const workspaceId = getLocalStorageItem("workspaceId");
  const token = await getAuthHeader();
  return instance
    .put(
      `/notification/updateNotificationAlert/${id}`,
      { ...formData, workspaceId: workspaceId },
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const SoftDeleteNotification = async (id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const token = await getAuthHeader();
  return instance
    .put(`/notification/softDelete/${id}`, {}, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const getUserSocialProfile = async () => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const token = await getAuthHeader();

  const workspaceId = getLocalStorageItem("workspaceId");

  return instance
    .get(`/socialProfiles/integration?workspaceId=${workspaceId}`, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const getSocialProfilesByWorkspaceId = async (id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const token = await getAuthHeader();

  return instance
    .get(`/socialProfiles/integration/socialProfiles?workspaceId=${id}`, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            socialMediaSettings: response.socialMediaSettings,
            message: response.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

// const GmailAccesTokenService = async (formData, workspaceLogo) => {
//   const responseData = {
//     loading: false,
//     status: 210,
//     message: "Something went wrong, Please try again.",
//   };
//   const token = await getAuthHeader();
//   const workspaceId = await getWorkspaceId();
//   return instance
//     .post(
//       `/conversation/gmail-threads/url`,
//       {
//         ...formData,
//         workspaceId: workspaceId,
//         workspaceLogo: workspaceLogo,
//       },
//       token
//     )
//     .then((response) => {
//       if (response.status === 200 || response.status === 201) {
//         response = response.data;
//         if (response.code === 200) {
//           return {
//             ...responseData,
//             status: 200,
//             data: response.data,
//           };
//         } else {
//           return {
//             ...responseData,
//             message: response.message,
//           };
//         }
//       } else {
//         return {
//           ...responseData,
//           message: ParseError(response.data),
//         };
//       }
//     })
//     .catch((err) => {
//       return {
//         ...responseData,
//         message: ParseError(
//           err.response && err.response.data ? err.response.data : err.message
//         ),
//       };
//     });
// };
const GmailAccesTokenService = async (formData, workspaceLogo) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();
  return instance
    .post(
      `/integration/`,
      {
        ...formData,
        workspaceId: workspaceId,
        workspaceLogo: workspaceLogo,
        type: "gmail",
      },
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const UpdateAllNotifications = async (userId) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .post(`/notification/updateAllNotifications`, { userId: userId }, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
// const gettingGoogleCredentials = async (code) => {
//   const responseData = {
//     loading: false,
//     status: 210,
//     message: "Something went wrong, Please try again.",
//   };
//   const token = await getAuthHeader();
//   const workspaceId = await getWorkspaceId();
//   const email = await gettingEmail();

//   return instance
//     .get(
//       `/conversation/gmail-threads/saveTokenGmail?workspaceId=${workspaceId}&code=${code}&email=${email}`,
//       token
//     )
//     .then((response) => {
//       if (response.status === 200 || response.status === 201) {
//         response = response.data;
//         if (response.code === 200) {
//           return {
//             ...responseData,
//             status: 200,
//             data: response.data,
//             message: response.message,
//           };
//         } else {
//           return {
//             ...responseData,
//             message: response.message,
//           };
//         }
//       } else {
//         return {
//           ...responseData,
//           message: ParseError(response.data),
//         };
//       }
//     })
//     .catch((err) => {
//       return {
//         ...responseData,
//         message: ParseError(
//           err.response && err.response.data ? err.response.data : err.message
//         ),
//       };
//     });
// };
const gettingGoogleCredentials = async (code) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();

  return instance
    .get(
      `/integration?workspaceId=${workspaceId}&code=${code}&type=gmail`,
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const gettingTikTokCredentials = async (data) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();
  return instance
    .post(`/socialProfiles/integration/`, { ...data, workspaceId }, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.message,
          };
        } else {
          console.log("esleeeee1");
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        console.log("elsee2", response.data);
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log("errrrrrrr", err);
      // return {
      //   ...responseData,
      //   message: ParseError(
      //     err.response && err.response.data ? err.response.data : err.message
      //   ),
      // };
      return {
        ...responseData,
        message: "Page already exists!",
      };
    });
};

const checkingLoginWithGoogle = async () => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();
  return instance
    .get(
      `/conversation/gmail-threads/isLoginWithGoogle?workspaceId=${workspaceId}`,
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            isLogin: response.isLogin,
            message: response.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const FetchAllGoogleAccounts = async () => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();
  return instance
    .get(`/conversation/gmail-threads/all?workspaceId=${workspaceId}`, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.message,
            count: response.count,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const CheckFbr = async (id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
    flag: false,
  };
  const token = await getAuthHeader();
  return instance
    .get(`/workspaces/fbrCheck?workspaceId=${id}`, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            flag: response.flag,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      // console.log(err, "err");
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const FbrIntegration = async (key, accessToken, workspaceId, ntn) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  console.log("came here", key, accessToken, workspaceId);
  return instance
    .post(
      `/workspaces/integrateFbr`,
      { workspaceId, key, accessToken, ntn },
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            message: response.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      // console.log(err, "err");
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const GetWorkSpaces = async () => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .get("/workspaces/all", token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: response.code,
            data: response.data,
            message: response.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const GetWorkspacesDetail = async (page = 1, limit = 100000) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const token = await getAuthHeader();
  return instance
    .get(`/workspaces/workspacesDetails`, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: response.code,
            data: response.data,
            message: response.message,
            count: response.count,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      // console.log(err, "err");
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const DeleteGoogleAccount = async (id, email) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .delete(
      `/conversation/gmail-threads?workspaceId=${id}&email=${email}`,
      token
    )
    .then((response) => {
      if (response.status === 200) {
        response = response.data;
        if (response.code === 200) {
          const data = response.data;
          return {
            ...responseData,
            status: 200,
            message: response.message,
            data,
          };
        }
        return {
          ...responseData,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const FetchGoogleAccount = async (id, email) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .get(
      `/gmail-threads/accountByEmail?workspaceId=${id}&email=${email}`,
      token
    )
    .then((response) => {
      if (response.status === 200) {
        response = response.data;
        if (response.code === 200) {
          const data = response.data;
          return {
            ...responseData,
            status: 200,
            message: response.message,
            data,
          };
        }
        return {
          ...responseData,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const FetchThreadMails = async (id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .get(
      `/conversation/gmail-threads/threads?threadId=${id}&workspaceId=${workspaceId}`,
      token
    )
    .then((response) => {
      if (response.status === 200) {
        response = response.data;
        if (response.code === 200) {
          const data = response.data;
          return {
            ...responseData,
            status: 200,
            message: response.message,
            data,
          };
        }
        return {
          ...responseData,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const ReadThreadMails = async (id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();

  return instance
    .get(
      `/conversation/gmail-threads/read?id=${id}&workspaceId=${workspaceId}`,
      token
    )
    .then((response) => {
      if (response.status === 200) {
        response = response.data;
        if (response.code === 200) {
          const data = response.data;
          return {
            ...responseData,
            status: 200,
            message: response.message,
            data,
          };
        }
        return {
          ...responseData,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const FetchEmailThreadsByQuery = async (query) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();

  return instance
    .get(
      `/gmail-threads/search?query=${query}&workspaceId=${workspaceId}`,
      token
    )
    .then((response) => {
      if (response.status === 200) {
        response = response.data;
        if (response.code === 200) {
          const data = response.data;
          return {
            ...responseData,
            status: 200,
            message: response.message,
            data,
          };
        }
        return {
          ...responseData,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const UpdateUserToken = async (workspace) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();

  const data = {
    token: token?.headers?.authorization?.split("Bearer ")?.[1],
    workspaceId: workspace?._id,
  };

  return instance
    .post("/auth/updateToken", data, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            status: 200,
            data: response.data,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const GetUserWorkspaceSettings = async (id = "") => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const token = await getAuthHeader();
  return instance
    .get(`/shared-apis?workspaceId=${id}`, token)
    .then((response) => {
      if (response.status === 200) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export {
  GetAllNotification,
  UpdateUserToken,
  GetUserWorkSpaces,
  GetWorkSpace,
  AddWorkSpace,
  UpdateWorkSpace,
  DeleteWorkspace,
  GetWorkspaceUsers,
  UpdateNotification,
  getUserSocialProfile,
  GmailAccesTokenService,
  UpdateAllNotifications,
  gettingGoogleCredentials,
  checkingLoginWithGoogle,
  SoftDeleteNotification,
  GetNotification,
  UpdateNotificationAlert,
  gettingTikTokCredentials,
  CheckFbr,
  FbrIntegration,
  GetWorkSpaces,
  ResetWorkspace,
  GetWorkspacesDetail,
  getWorkSpaceAgents,
  FetchAllGoogleAccounts,
  DeleteGoogleAccount,
  FetchGoogleAccount,
  FetchThreadMails,
  ReadThreadMails,
  FetchEmailThreadsByQuery,
  GetWebsite,
  GetWorkspaceAllUsers,
  GetWorkspaceAllUsersByModule,
  GetWorkspaceSettings,
  updateProductSettings,
  updateOrderSettings,
  updateUserNoficationSettings,
  getSocialProfilesByWorkspaceId,
  getWorkSpaceMembers,
  removeWorkSpaceMember,
  AddWorkSpaceMember,
  applyRoleToWorkSpaceMember,
  updateSocialMediaSettings,
  UpdateChatGptApiKey,
  GetUserWorkspaceSettings,
};
